/**
 * Copyright 2017 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/

.summit-dropdown {
    .summit-select {
        width: 150px;

        & > div {
            border-top-right-radius: unset;
            border-bottom-right-radius: unset;
        }
    }

    .btn {
        border-left-color: #e7e7e7;
        height: 38px;
    }

    &.big {
        .summit-select input {
            height: 32px
        }

        .btn {
            height: 46px;
        }
    }
}
