.bulk-actions-selector-container{
  min-height: 90px;

  div {

    padding:1em;

    input[type=checkbox]{
      margin-right: 1em;
    }

    select {
      height: 30px;
      width: 90%;
    }
  }
}
