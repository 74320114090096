table.table {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
}
table.table td,
table.table th {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
table.table td.tables_empty,
table.table th.tables_empty {
  text-align: center;
}
table.table thead tr th.sorting,
table.table thead tr td.sorting,
table.table thead tr th.sorting_asc,
table.table thead tr td.sorting_asc,
table.table thead tr th.sorting_desc,
table.table thead tr td.sorting_desc {
  min-width: 30px;
  padding-right: 10px;
}
table.table thead tr th.sorting,
table.table thead tr td.sorting,
table.table thead tr th.sorting_asc,
table.table thead tr td.sorting_asc,
table.table thead tr th.sorting_desc,
table.table thead tr td.sorting_desc,
table.table thead tr th.sorting_asc_disabled,
table.table thead tr td.sorting_asc_disabled,
table.table thead tr th.sorting_desc_disabled,
table.table thead tr td.sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}
table.table thead tr th.sorting:after,
table.table thead tr td.sorting:after,
table.table thead tr th.sorting_asc:after,
table.table thead tr td.sorting_asc:after,
table.table thead tr th.sorting_desc:after,
table.table thead tr td.sorting_desc:after,
table.table thead tr th.sorting_asc_disabled:after,
table.table thead tr td.sorting_asc_disabled:after,
table.table thead tr th.sorting_desc_disabled:after,
table.table thead tr td.sorting_desc_disabled:after {
  position: relative;
  left: 8px;
  top: 2px;
  display: inline-block;
  font-family: 'Glyphicons Halflings', sans-serif;
  opacity: 0.5;
}
table.table thead tr th:active,
table.table thead tr td:active {
  outline: none;
}
table.table thead tr th.sorting:after,
table.table thead tr td.sorting:after {
  opacity: 0.2;
  content: "\e150";
}
table.table thead tr th.sorting_asc:after,
table.table thead tr td.sorting_asc:after {
  content: "\e155";
}
table.table thead tr th.sorting_desc:after,
table.table thead tr td.sorting_desc:after {
  content: "\e156";
}
table.table thead tr th.sorting_asc_disabled:after,
table.table thead tr td.sorting_asc_disabled:after,
table.table thead tr th.sorting_desc_disabled:after,
table.table thead tr td.sorting_desc_disabled:after {
  color: #eee;
}
table.table.nowrap th,
table.table.nowrap td {
  white-space: nowrap;
}
table.table tbody tr.child {
  padding: 0.5em 1em;
}
table.table tbody tr.child:hover {
  background: transparent !important;
}
table.table tbody tr.child ul {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
table.table tbody tr.child ul li {
  border-bottom: 1px solid #efefef;
  padding: 0.5em 0;
}
table.table tbody tr.child ul li:first-child {
  padding-top: 0;
}
table.table tbody tr.child ul li:last-child {
  border-bottom: none;
}
table.table tbody tr.can-edit {
  cursor: pointer;
}
table.table .actions {
  width: 70px;
}
table.table .actions a {
  margin-left: 10px;
}
table.table .actions a .delete-icon:hover {
  color: #da0000 !important;
}
table.table .actions a .fa:hover {
  color: #024c85;
}
table.table-bordered.table {
  border-collapse: separate !important;
}
table.table-bordered.table th,
table.table-bordered.table td {
  border-left-width: 0;
}
table.table-bordered.table th:last-child,
table.table-bordered.table td:last-child {
  border-right-width: 0;
}
table.table-bordered.table tbody th,
table.table-bordered.table tbody td {
  border-bottom-width: 0;
}
