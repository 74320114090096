/**
 * Copyright 2017 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/

.action-dropdown {
  .action-select {
    width: 250px;
    .Select-control {
      border-radius: 4px 0 0 4px;
      height: 100%;
      .Select-input {
        height: 32px;
      }
    }
  }
  &.small {
    :global .Select-control {
      height: 30px;
    }
    :global .Select-placeholder {
      line-height: 20px
    }
    :global .Select-value {
      line-height: 26px !important;
    }

    :global .Select-value-label {
      line-height: 26px;
    }

    :global .Select-input {
      height: 26px;
    }
  }
  &.normal {
    .btn {
      height: 38px;
    }
  }

}
