.editor-input {
    div {
        a {
            color: #337ab7;
            &:hover,
            a:focus {
                color: #23527c;
                text-decoration: underline;
            }
        }
    }
}
