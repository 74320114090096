@import "../table/table.less";

.sortable-table-box {
    margin-top: 20px;
}

.checkbox-cell{
    text-align: center;
    /* center checkbox horizontally */
    vertical-align: middle;
    /* center checkbox vertically */
}

