/**
 * Copyright 2017 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/


.dropzone {
    height: 150px;
    border: 2px dashed gray;
    padding: 20px;
    color: gray;
    text-align: center;
}

.file-box {
    max-width: 50px;
    max-height: 50px;
    position: relative;
    text-align: center;

    img {
        width: 100%;
    }

    .remove {
        position: absolute;
        top: -5px;
        right: -10px;
        height: 20px;
        width: 20px;
        cursor: pointer;
        color: red;
    }
}


