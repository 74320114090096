/**
 * Copyright 2017 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/

.simple-link-list {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  .link-select {
    width: 200px;
    .Select-control {
      border-radius: 4px 0 0 4px;
      height: 100%;
      .Select-input {
        height: 32px;
      }
    }
  }
  .add-button {
    height: 38px;
    border-color: #afafaf;
  }
}

.simple-link-list:not(.panel .simple-link-list) {
  padding: 10px;
  border: 1px solid #cccccc;
  margin: 5px;
  border-radius: 3px;
}

.panel .simple-link-list {
  .simple-link-list-title {
    display: none;
  }
  .simple-link-list-buttons {
    display: flex;
    justify-content: flex-end;
  }
}