/* Filepicker CSS */
.filepicker {
  font-family: sans-serif;
}

div.filepicker {
  text-align: center;
  padding: 5px;
  background-color: #E1E1E1;
  border-radius: 5px;
  min-height: 100px;
  height: 100%;
  border: 2px dashed #C7C7C7;
}

div.filepicker.disabled {
  cursor: not-allowed;
  padding-top: 30px;
}

/* Icon */
.filepicker-file-icon
{
  position: relative;

  display: inline-block;

  margin: 1.5em 0 2.5em 0;
  padding-left: 45px;

  color: black;
}

.filepicker-file-icon::before
{
  position: absolute;
  top: -7px;
  left: 0;

  width: 29px;
  height: 34px;

  content: '';

  border: solid 2px #7F7F7F;
  border-radius: 2px;
}

.filepicker-file-icon::after
{
  font-size: 11px;
  line-height: 1.3;

  position: absolute;
  top: 9px;
  left: -4px;

  padding: 0 2px;

  content: 'file';
  content: attr(data-filetype);
  text-align: right;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #fff;
  background-color: #000;
}
.filepicker-file-icon .fileCorner
{
  position: absolute;
  top: -7px;
  left: 22px;

  width: 0;
  height: 0;

  border-width: 11px 0 0 11px;
  border-style: solid;
  border-color: white transparent transparent #920035;
}
.upload-input-v2-preview-container{
  list-style: none;
  li {
    display: grid;
    grid-template-columns: 25% 70% 5%;
    align-items: center;
    border: 1px solid #E1E1E1;
    margin: 1em;

    .file-preview {
      img {
        max-width: 150px;
        max-height: 100px;
        width: auto;
        height: 100px;
        padding: 1em;
      }
    }

    .file-name {
      margin-left: 2em;
      text-align: left;
    }

    .file-delete {
      text-align: center;
    }
  }
}
