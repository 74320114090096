
.extra-question-error {
    display: block;
    color: red;
    font-size: 12px;
    font-weight: bold;
    padding: 0;
    margin: auto 0;
}

.extra-question-error:before{
    font-family: FontAwesome;
    content: "\f071";
    padding-right: 5px;
}

.question-label-container{
    a{
        text-decoration: underline !important;
    }
}
.questions-form {
    h4{
        font-size: 12pt;
    }
    .abc-checkbox{
        padding: 0 !important;
    }
    .form-check-label{
        font-weight: normal !important;
    }
    padding-bottom: 5em;
    .abc-radio label{
        max-width: 90%;
    }
    form > div.checkbox-wrapper {
        display: grid;
        gap: 15px;
        grid-template-columns: 0fr 14fr;
        grid-template-rows: 1fr;
        align-items: start;
        .input-wrapper {
            width: 35px;
            .form-check {
                width: 35px;
            }
        }
        .checkbox-mandatory {
            position: absolute;
            color: #FF0000;
        }
        .eq-checkbox-label {
            padding-top: 0px;
        }
    }
}
